import { useState } from 'react';
import Link from 'next/link';

import Button from '../../common/Button/Button';
import { H3, H4, H5 } from '../../common/headline';
import Icon from '../../common/Icons/Icon';
import Logo from '../../common/Logos/Logo';
import { CountrySelector } from './CountrySelector';
import { TakeOurQuizCTA } from '../Hero/TakeOurQuizCTA';

export const MobileNavBar = ({
  utmMedium,
  utmCampaign,
  utmSource,
}: {
  utmMedium?: string;
  utmCampaign?: string;
  utmSource?: string;
}): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!isExpanded) {
    return (
      <div>
        <div className="flex justify-between bg-[rgba(0,0,0,0)] px-3 py-4">
          <div className="relative h-[32px] w-[101px]">
            <Link href="/">
              <Logo priority name="long" theme="dark" placeholder="blur" />
            </Link>
          </div>
          <div className="flex h-fit">
            <CountrySelector />
            <div
              className="ml-3 fill-blue stroke-blue"
              onClick={() => setIsExpanded(true)}
            >
              <Icon name="hamburger" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="sn-grid m-0 h-screen grid-cols-1 bg-dawn-blue-50 px-3 pb-5 pt-2">
      <div className="mb-10 flex justify-between">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue">
          <div className="relative h-3 w-6">
            <Link href="/">
              <Logo name="short" theme="light" priority placeholder="blur" />
            </Link>
          </div>
        </div>
        <div
          className="fill-blue stroke-blue"
          onClick={() => setIsExpanded(false)}
        >
          <Icon name="cross" />
        </div>
      </div>
      <div className="mb-10 text-blue">
        <div className="mb-4">
          <H3>How It Works</H3>
        </div>
        <div className="mb-4">
          <H5>
            <Link href="/how-it-works">Weight-loss programme</Link>
          </H5>
        </div>
        <div className="mb-4">
          <H5>
            <Link href="/wegovy-weight-loss-programme">
              Wegovy weight-loss programme
            </Link>
          </H5>
        </div>
        <div className="mb-4">
          <H5>
            <Link href="/mounjaro-weight-loss-programme">
              Mounjaro weight-loss programme
            </Link>
          </H5>
        </div>
        <div className="mb-4">
          <Link href="/stories">
            <H3>Success Stories</H3>
          </Link>
        </div>
        <div className="mb-4">
          <Link href="/guides">
            <H3>Recipes and Guides</H3>
          </Link>
        </div>
        <div className="mb-4">
          <Link href="/our-story">
            <H3>Our Story</H3>
          </Link>
        </div>
        <div className="mb-4">
          <Link href="/nhs-and-partnerships">
            <H3>NHS & Partnerships</H3>
          </Link>
        </div>
      </div>
      <div>
        <div className="mb-4 flex flex-col text-blue">
          <TakeOurQuizCTA
            utmCampaign={utmCampaign}
            utmMedium={utmMedium}
            utmSource={utmSource}
            location="Navbar"
          />
        </div>
        <div className="mb-6 flex w-full max-w-full flex-col text-blue md:max-w-[164px]">
          <Link href="/login">
            <Button colour="NavyBlue" type="Secondary" fullWidth>
              Log in
            </Button>
          </Link>
        </div>
        <div className="mb-7 h-0 w-full rounded-full border-t border-dawn-blue-400"></div>
        <div className="mb-6 flex justify-between text-blue">
          <a
            href="https://help.secondnature.io/en/"
            target="_blank"
            rel="noreferrer"
          >
            <H4>Support</H4>
          </a>
        </div>
      </div>
    </div>
  );
};
