import { MobileFooter } from './MobileFooter';
import { DesktopFooter } from './DesktopFooter';

export const Footer = (): JSX.Element => (
  <footer className="min-w-full">
    <div className="bg-blue text-mono-white">
      <div className="lg:hidden">
        <MobileFooter />
      </div>
      <div className="hidden lg:block">
        <DesktopFooter />
      </div>
    </div>
  </footer>
);
