import Link from 'next/link';

import Button, { ButtonProps } from '@common/Button/Button';
import { getTakeOurHealthQuizLink } from '@nextUtils/guidesUtils';
import { trackClickedGetQuiz } from '@nextUtils/trackingUtils';
import { useHasAnyMedicationAvailability } from '@hooks/useHasAnyMedicationAvailability';

type Props = {
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
  utmContent?: string;
  location: string;
  ctaText?: string;
  isGlp1?: boolean;
};

export const TakeOurQuizLink = ({
  utmCampaign,
  utmMedium,
  utmSource,
  utmContent,
  location,
  ctaText = 'Take our quiz',
  isGlp1,
  children,
}: Props & { children: JSX.Element }): JSX.Element => {
  const allowGLP1Signups = useHasAnyMedicationAvailability();

  const isGlp1Rejoiner = isGlp1 && !allowGLP1Signups;
  const quizLink = getTakeOurHealthQuizLink(
    utmMedium,
    utmCampaign,
    utmSource,
    utmContent,
    isGlp1Rejoiner,
  );
  const linkKey = isGlp1Rejoiner ? 'restart' : 'get-plan';

  const onPress = () =>
    trackClickedGetQuiz({
      ctaText,
      location,
      utmMedium,
      utmCampaign,
      utmContent,
      isGlp1,
    });

  return (
    <Link href={quizLink} key={linkKey} onClick={onPress}>
      {children}
    </Link>
  );
};

export const TakeOurQuizButton = ({
  utmCampaign,
  utmMedium,
  utmSource,
  utmContent,
  location,
  ctaText = 'Take our quiz',
  isGlp1,
  ...buttonProps
}: Props & ButtonProps): JSX.Element => (
  <div className="w-full">
    <TakeOurQuizLink
      {...{
        utmCampaign,
        utmMedium,
        utmSource,
        utmContent,
        location,
        ctaText,
        isGlp1,
      }}
    >
      <Button {...buttonProps}>{ctaText}</Button>
    </TakeOurQuizLink>
  </div>
);

export const TakeOurQuizCTA = (
  props: Props & Pick<ButtonProps, 'isSmall'>,
): JSX.Element => (
  <>
    <div className="md:hidden">
      <TakeOurQuizButton
        colour="NavyBlue"
        fullWidth
        type="Primary"
        {...props}
      />
    </div>
    <div className="hidden md:block">
      <TakeOurQuizButton colour="NavyBlue" type="Primary" {...props} />
    </div>
  </>
);
