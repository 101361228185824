import { useState } from 'react';

import CountrySelectorModal from '@components/Modals/CountrySelectorModal';

import { ButtonLabel } from '../../common/Button/ButtonLabel';
import Icon from '../../common/Icons/Icon';
import { useCountryCodeFromLocale } from '../../hooks/generalHooks';

export const CountrySelector = (): JSX.Element => {
  const countryCode = useCountryCodeFromLocale();
  const [isCountrySelectorOpen, setCountrySelectorOpen] = useState(false);
  return (
    <div
      className="flex cursor-pointer items-center"
      onClick={() => setCountrySelectorOpen(!isCountrySelectorOpen)}
    >
      <CountrySelectorModal
        isModalOpen={isCountrySelectorOpen}
        onRequestClose={() => setCountrySelectorOpen(false)}
      />
      <ButtonLabel>
        <span className="text-blue">{countryCode}</span>
      </ButtonLabel>
      <div className="fill-blue stroke-blue pl-1">
        <Icon name="world" />
      </div>
    </div>
  );
};
