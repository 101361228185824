import Link from 'next/link';

type Props = {
  children: React.ReactNode;
  url: string;
};

export const TextLinkS = ({ children, url }: Props): JSX.Element => (
  <div className="m-0 font-cera text-[16px] font-medium leading-[20px] tracking-[-0.22px]">
    <Link href={url}>{children}</Link>
  </div>
);

export const TextLinkXS = ({ children, url }: Props): JSX.Element => (
  <div className="m-0 font-cera text-[14px] font-medium leading-relaxed tracking-[-0.22px]">
    <Link href={url}>{children}</Link>
  </div>
);
