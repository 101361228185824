import { BodyRegular, BodySmall } from '../../common/body';
import { H5 } from '../../common/headline';
import Icon from '../../common/Icons/Icon';
import Accordion from '../Accordion/Accordion';
import { TextLinkS, TextLinkXS } from '../../common/textLink';
import Logo from '../../common/Logos/Logo';

export const MobileFooter = (): JSX.Element => (
  <div className="sn-grid grid-cols-1 pb-[80px] pt-[64px]">
    <div className="mb-[32px] flex justify-center">
      <div className="relative h-[32px] w-[101px]">
        <Logo theme="light" />
      </div>
    </div>
    <Accordion heading={<H5>Get started</H5>}>
      <div className="flex flex-col">
        <div className="mb-4 mt-[12px]">
          <TextLinkXS url="/how-it-works">Weight-loss programme</TextLinkXS>
        </div>
        <div className="mb-4">
          <TextLinkXS url="/wegovy-weight-loss-programme">
            Wegovy weight-loss programme
          </TextLinkXS>
        </div>
        <div className="mb-4">
          <TextLinkXS url="/mounjaro-weight-loss-programme">
            Mounjaro weight-loss programme
          </TextLinkXS>
        </div>
        <div className="mb-2">
          <TextLinkXS url="/faqs">FAQs</TextLinkXS>
        </div>
      </div>
    </Accordion>
    <Accordion heading={<H5>Learn more</H5>}>
      <div className="mb-4 mt-[12px] flex flex-col">
        <TextLinkXS url="/our-story">Our Story</TextLinkXS>
      </div>
      <div className="mb-4 mt-[12px] flex flex-col">
        <TextLinkXS url="https://apply.workable.com/secondnature/?utm_source=Second%20Nature%20Website">
          Careers
        </TextLinkXS>
      </div>
      <div className="mb-4 flex flex-col">
        <TextLinkXS url="/terms">Terms and Conditions</TextLinkXS>
      </div>
      <div className="mb-4 flex flex-col">
        <TextLinkXS url="/privacy">Privacy Policy</TextLinkXS>
      </div>
      <div className="mb-4 flex flex-col">
        <TextLinkXS url="/complaints">Complaints Procedure</TextLinkXS>
      </div>
      <div className="mb-2 flex flex-col">
        <TextLinkXS url="/pharmacy-services">Pharmacy Services</TextLinkXS>
      </div>
    </Accordion>
    <Accordion heading={<H5>Resources</H5>}>
      <div className="flex flex-col">
        <div className="mb-4 mt-[12px]">
          <TextLinkXS url="/guides">Recipes and Guides</TextLinkXS>
        </div>
        <div className="mb-4">
          <TextLinkXS url="/stories">Success Stories</TextLinkXS>
        </div>
        <div className="mb-4">
          <TextLinkXS url="/blog">Blog</TextLinkXS>
        </div>
        <div className="mb-2">
          <TextLinkXS url="https://shop.secondnature.io/">Shop</TextLinkXS>
        </div>
      </div>
    </Accordion>
    <Accordion heading={<H5>Partners</H5>}>
      <div className="mb-2 mt-[12px] flex flex-col">
        <TextLinkXS url="/nhs-and-partnerships">
          NHS and Partnerships
        </TextLinkXS>
      </div>
    </Accordion>
    <div className="mb-[6px] mt-[62px] flex w-[88px] justify-between">
      <a
        className="fill-mono-white stroke-mono-white"
        href="https://www.facebook.com/secondnature.io"
      >
        <Icon name="meta" />
      </a>
      <a
        className="fill-mono-white stroke-mono-white"
        href="https://twitter.com/snhealth_uk"
      >
        <Icon name="twitter" />
      </a>
      <a
        className="fill-mono-white stroke-mono-white"
        href="https://www.instagram.com/secondnature_health"
      >
        <Icon name="instagram" />
      </a>
    </div>

    <H5>Second Nature®</H5>

    <div className="mb-10">
      <div className="mb-6 flex">
        <BodyRegular>Email:</BodyRegular>
        <TextLinkS url="mailto:hello@secondnature.io">
          <p className="ml-[4px] font-medium">hello@secondnature.io</p>
        </TextLinkS>
      </div>
      <div className="flex">
        <BodyRegular>Press:</BodyRegular>
        <TextLinkS url="mailto:press@secondnature.io">
          <p className="ml-[4px] font-medium">press@secondnature.io</p>
        </TextLinkS>
      </div>
    </div>

    <div className="flex justify-center">
      <div className="flex max-w-[215px] text-center">
        <BodyRegular>483 Green Lanes, London, N13 4BS</BodyRegular>
      </div>
    </div>

    <div className="text-center text-mono-white/30">
      <BodySmall>
        Registered Pharmacy: Pharmalogic, 464 Ranglet Road, Walton Summit
        Centre, Bamber Bridge, Preston, Lancashire, PR5 8AR, United Kingdom
      </BodySmall>
      <BodySmall>
        Registration: GPhC 9010938, Superintendent Pharmacist: Haroon Amanji
      </BodySmall>
      <br />
      <BodySmall>
        Registration No: 08511152 registered in England and Wales
      </BodySmall>
      <BodySmall>
        © Second Nature Healthy Habits Ltd {new Date().getFullYear()}
      </BodySmall>
    </div>
  </div>
);
