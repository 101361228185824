import Image from 'next/legacy/image';

import logoWhite from '@public/img/logo/sn-logo-white@3x.png';
import logoBlue from '@public/img/logo/second-nature-logo@2x.png';
import logoShort from '@public/img/logo/sn-logo-acronym-white@2x.png';

const logos = {
  short: logoShort,
  dark: logoBlue,
  light: logoWhite,
};

export type LogoName = 'short' | 'long';

export type LogoTheme = 'dark' | 'light';

type Props = {
  name?: LogoName;
  theme?: LogoTheme;
  priority?: boolean | undefined;
  placeholder?: 'blur' | 'empty' | undefined;
};

const Logo = ({
  name = 'long',
  theme = 'dark',
  priority,
  placeholder,
}: Props): JSX.Element => {
  const chosenImage = name === 'short' ? logos.short : logos[theme];
  return (
    <Image
      src={chosenImage}
      alt="Second Nature logo"
      priority={priority}
      placeholder={placeholder}
      layout="fill"
    />
  );
};

export default Logo;
