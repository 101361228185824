import { useEffect, useState } from 'react';

import { MobileNavBar } from './MobileNavBar';
import { DesktopNavBar } from './DesktopNavBar';

export const NavBar = ({
  utmMedium,
  utmCampaign,
  utmSource,
}: {
  utmMedium?: string;
  utmCampaign?: string;
  utmSource?: string;
}): JSX.Element => {
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    setIsScrolledDown(window.scrollY >= 1);

    let requestRunning: null | number = null;
    function handleScroll() {
      if (requestRunning === null) {
        // requestAnimationFrame is used to throttle scroll triggers
        requestRunning = window.requestAnimationFrame(() => {
          if (window.scrollY >= 1 && !isScrolledDown) {
            setIsScrolledDown(true);
          } else if (window.scrollY === 0 && isScrolledDown) {
            setIsScrolledDown(false);
          }
          requestRunning = null;
        });
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isScrolledDown]);

  const baseHeaderClass =
    'z-50 fixed top-0 w-full transition ease-in-out duration-300';
  const scrolledDownClass = 'shadow bg-mono-white';

  const headerClass = isScrolledDown
    ? `${baseHeaderClass} ${scrolledDownClass}`
    : baseHeaderClass;

  return (
    <header className={headerClass}>
      <div className="xl:hidden">
        <MobileNavBar
          utmMedium={utmMedium}
          utmCampaign={utmCampaign}
          utmSource={utmSource}
        />
      </div>
      <div className="hidden xl:block">
        <DesktopNavBar
          utmMedium={utmMedium}
          utmCampaign={utmCampaign}
          utmSource={utmSource}
        />
      </div>
    </header>
  );
};
