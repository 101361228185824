import { useContext, useMemo } from 'react';

import { MainContext } from '@components/context/MainProvider';

/**
 * Hook to check whether we have any available medication programmes
 * regardless of the individual availability of each programme.
 */
export const useHasAnyMedicationAvailability = (): boolean => {
  const { medicationAvailability } = useContext(MainContext);

  const hasMedicationAvailability = useMemo(
    () =>
      Object.values(medicationAvailability).some(
        medication => medication.isAvailable,
      ),
    [medicationAvailability],
  );

  return hasMedicationAvailability;
};
