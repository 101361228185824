import { useState } from 'react';

import Icon from '@common/Icons/Icon';

type Props = {
  children: React.ReactNode;
  heading: React.ReactNode;
  iconColour?: 'blue';
};

const Accordion = ({ children, heading, iconColour }: Props): JSX.Element => {
  const [showChildren, setShowChildren] = useState(false);
  return (
    <div>
      <div
        onClick={() => setShowChildren(!showChildren)}
        className="flex flex-1 cursor-pointer"
      >
        <div className="flex flex-1 items-center">{heading}</div>
        <div
          className={`flex items-center ${
            iconColour === 'blue'
              ? 'fill-blue stroke-blue'
              : 'fill-mono-white stroke-mono-white'
          }`}
        >
          {showChildren ? <Icon name="cross" /> : <Icon name="plus" />}
        </div>
      </div>
      {showChildren && <div>{children}</div>}
    </div>
  );
};

export default Accordion;
