import Link from 'next/link';
import { useRouter } from 'next/router';

import Button from '../../common/Button/Button';
import { ButtonLabel } from '../../common/Button/ButtonLabel';
import { H6 } from '@common/headline';
import Logo from '../../common/Logos/Logo';
import { TakeOurQuizCTA } from '../Hero/TakeOurQuizCTA';
import { CountrySelector } from './CountrySelector';

export const DesktopNavBar = ({
  utmMedium,
  utmCampaign,
  utmSource,
}: {
  utmMedium?: string;
  utmCampaign?: string;
  utmSource?: string;
}): JSX.Element => {
  const { asPath } = useRouter();

  const isActive = (path: string): boolean => asPath.startsWith(path);

  return (
    <div>
      <div className="flex flex-row items-center py-4 text-blue md:px-[60px]">
        <div className="container m-auto flex flex-row justify-between">
          <div>
            <div className="relative h-8 w-[101px]">
              <Link href="/">
                <Logo name="long" theme="dark" priority placeholder="blur" />
              </Link>
            </div>
          </div>
          <div>
            <div className="flex flex-row items-center">
              <div className="mr-[25px] xxl:mr-[40px]">
                <div className="peer relative">
                  <ButtonLabel>
                    <Link
                      href="/how-it-works"
                      className={
                        isActive('/how-it-works')
                          ? 'border-b-2 border-blue'
                          : ''
                      }
                    >
                      How It Works
                    </Link>
                  </ButtonLabel>
                </div>
                <div className="absolute hidden hover:flex peer-hover:flex">
                  <div className="mt-2 w-[310px] flex-col rounded-lg bg-mono-white py-6">
                    <div className="border-b border-dawn-blue-200 px-6 pb-5">
                      <H6>
                        <Link href="/how-it-works">Weight-loss programme</Link>
                      </H6>
                    </div>
                    <div className="border-b border-dawn-blue-200 px-6 py-5">
                      <H6>
                        <Link href="/wegovy-weight-loss-programme">
                          Wegovy weight-loss programme
                        </Link>
                      </H6>
                    </div>
                    <div className="px-6 pt-5">
                      <H6>
                        <Link href="/mounjaro-weight-loss-programme">
                          Mounjaro weight-loss programme
                        </Link>
                      </H6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mr-[25px] xxl:mr-[40px]">
                <ButtonLabel>
                  <Link
                    href="/stories"
                    className={
                      isActive('/stories') ? 'border-b-2 border-blue' : ''
                    }
                  >
                    Success Stories
                  </Link>
                </ButtonLabel>
              </div>
              <div className="mr-[25px] xxl:mr-[40px]">
                <ButtonLabel>
                  <Link
                    href="/guides"
                    className={
                      isActive('/guides') ? 'border-b-2 border-blue' : ''
                    }
                  >
                    Recipes and Guides
                  </Link>
                </ButtonLabel>
              </div>
              <div className="mr-[25px] xxl:mr-[40px]">
                <ButtonLabel>
                  <Link
                    href="/our-story"
                    className={
                      isActive('/our-story') ? 'border-b-2 border-blue' : ''
                    }
                  >
                    Our Story
                  </Link>
                </ButtonLabel>
              </div>
              <div className="mr-[25px] xxl:mr-[40px]">
                <ButtonLabel>
                  <Link
                    href="/nhs-and-partnerships"
                    className={
                      isActive('/nhs-and-partnerships')
                        ? 'border-b-2 border-blue'
                        : ''
                    }
                  >
                    NHS & Partnerships
                  </Link>
                </ButtonLabel>
              </div>
              <div className="mr-2">
                <TakeOurQuizCTA
                  utmMedium={utmMedium}
                  utmCampaign={utmCampaign}
                  utmSource={utmSource}
                  location="Navbar"
                  isSmall
                />
              </div>
              <div className="mr-[25px] xxl:mr-[40px]">
                <Link href="/login" className="whitespace-nowrap">
                  <Button colour="NavyBlue" type="Secondary" isSmall>
                    Log in
                  </Button>
                </Link>
              </div>
              <CountrySelector />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
