import { MouseEventHandler } from 'react';

import { ButtonLabel } from './ButtonLabel';

export type ButtonProps = {
  children?: React.ReactNode;
  colour: 'NavyBlue' | 'Teal' | 'White';
  isDisabled?: boolean;
  icon?: JSX.Element;
  isSmall?: boolean;
  onPress?: MouseEventHandler<HTMLButtonElement> | undefined;
  type: 'Primary' | 'Secondary';
  buttonType?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  dataCy?: string;
};

export const generateButtonProperties = ({
  colour,
  icon,
  isSmall,
  type,
  fullWidth,
  isDisabled,
}: Pick<
  ButtonProps,
  'colour' | 'icon' | 'isSmall' | 'type' | 'fullWidth' | 'isDisabled'
>): string => {
  let padding = 'py-[11px] px-8';
  let width = '';

  if (icon) {
    padding = 'p-3';
  }

  if (isSmall) {
    padding = 'py-1 px-6';
  } else if (fullWidth) {
    width = 'w-full';
  }

  const cursor = isDisabled ? 'cursor-not-allowed' : 'cursor-pointer';

  // Set base colours before different use case scenarios override them
  let backgroundColour =
    'hover:bg-blue-600 active:bg-purple-200 disabled:bg-blue-200';
  let textColour =
    'text-blue hover:text-mono-white active:text-blue disabled:text-mono-white';
  let border = 'border-[1.5px] active:border-purple disabled:border-blue-400';
  let iconColour =
    'active:fill-blue active:stroke-blue disabled:fill-mono-white disabled:stroke-mono-white hover:fill-mono-white hover:stroke-mono-white';

  switch (type) {
    case 'Primary':
    default:
      switch (colour) {
        case 'NavyBlue':
        default:
          backgroundColour = `${backgroundColour} bg-blue`;
          border = `${border} border-blue`;
          textColour = `text-mono-white hover:text-mono-white active:text-blue disabled:text-mono-white`;
          iconColour = `${iconColour} fill-mono-white stroke-mono-white`;
          break;
        case 'White':
          backgroundColour = `${backgroundColour} bg-mono-white`;
          border = `${border} border-mono-white`;
          iconColour = `${iconColour} fill-blue stroke-blue`;
          break;
        case 'Teal':
          backgroundColour = `${backgroundColour} bg-teal`;
          border = `${border} border-teal`;
          iconColour = `${iconColour} fill-blue stroke-blue`;
          break;
      }
      break;

    case 'Secondary':
      backgroundColour = 'bg-transparent';
      textColour =
        'active:text-purple hover:text-purple disabled:text-blue-400';
      iconColour =
        'active:fill-purple active:stroke-purple disabled:fill-blue-400 disabled:stroke-blue-400';

      // Specifically for the Secondary icon buttons, we want a white background
      // if the button is pressed or disabled
      if (icon) {
        backgroundColour = 'active:bg-mono-white disabled:bg-mono-white';
      }

      switch (colour) {
        case 'NavyBlue':
        default:
          border = `${border} border-blue hover:border-purple`;
          textColour = `${textColour} text-blue`;
          iconColour = `${iconColour} stroke-blue fill-blue`;
          break;
        case 'White':
          border = `${border} border-mono-white`;
          textColour = `${textColour} text-mono-white`;
          iconColour = `${iconColour} stroke-mono-white fill-mono-white`;
          break;
        case 'Teal':
          border = `${border} border-teal`;
          textColour = `${textColour} text-teal`;
          iconColour = `${iconColour} stroke-teal fill-teal`;
          break;
      }
      break;
  }
  return `rounded-[52px] ${textColour} ${backgroundColour} ${border} ${padding} ${iconColour} ${width} ${cursor}`;
};

const Button = ({
  children,
  colour,
  isDisabled,
  icon,
  isSmall,
  onPress,
  type,
  buttonType = 'button',
  fullWidth,
  dataCy,
}: ButtonProps): JSX.Element => {
  const buttonClassNames = generateButtonProperties({
    type,
    colour,
    icon,
    isSmall,
    fullWidth,
    isDisabled,
  });
  const childComponent = icon || <ButtonLabel>{children}</ButtonLabel>;

  return (
    <button
      data-cy={dataCy}
      className={buttonClassNames}
      onClick={onPress}
      disabled={isDisabled}
      type={buttonType}
    >
      {childComponent}
    </button>
  );
};

export default Button;
